





























































import {Component, Vue} from "vue-property-decorator";
import H5img from "@/components/H5img.vue"
import H5font from "@/components/H5font.vue"
import {State, Action} from 'vuex-class'
import vdr from '@/components/vue-draggable-resizable-gorkys/components/vue-draggable-resizable.vue'
import html2canvas from 'html2canvas'
import bus from "@/bus";
import commen from "@/utils/Commen";

@Component({
  components: {
    H5img,
    H5font,
    vdr
  }
})
export default class H5 extends Vue {
  public zoom = 1
  public result = null

  @State('controlType') controlType
  @State('controlList') controlList
  @Action('setControlIndex') setControlIndex
  @Action('setControlType') setControlType
  @Action('setControlList') setControlList

  async mounted() {

    bus.$cookies.set("appToken",this.$route.query.Token)
    await this.setEditorBodyContentSize()
    await this.getTempData()
  }

  // 设置元素大小
  public setEditorBodyContentSize() {
    const editorBody = this.$refs.editorBody as any

    this.$nextTick(() => {
      const w = editorBody.offsetWidth
      const h = editorBody.offsetHeight

      //分辨率比例
      let p = 1
      let sw = 0
      let sh = 0
      let p1 = 1
      if (this.$route.params.type === '1') {
        p = 1920 / 1080
        sw = 1920
        sh = 1080
        p1 = w / h
      } else {
        p = 1080 / 1920
        sw = 1080
        sh = 1920
        p1 = h / w
      }

      //屏幕比例
      if (this.$route.params.type === '1') {
        if (p > p1) {
          this.zoom = w / sw
        } else {
          this.zoom = h / sh
        }
      } else {
        this.zoom = h / sh
      }
    })
  }

  // 获取模板数据
  public getTempData() {
    bus.$postapplet("/template/getTemplateById", {
      materialId: this.$route.params.cid
    }).then(res => {
      this.setControlList(res.data.material.templateParam)
    }).catch(err => {
      bus.$elementMessage(err, 'error')
    })
  }

}
