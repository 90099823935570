















import bus from "@/bus";
import {Component, Vue} from "vue-property-decorator";
import {State} from "vuex-class";

@Component
export default class H5img extends Vue {
  name = "h5img"

  @State("controlIndex") controlIndex
  @State("controlList") controlList

  public onReplace() {
    (this.$refs.upfile as any).click()
  }

  // 上传
  public upFile() {
    const file = (this.$refs.upfile as any).files[0]
    const formData = new FormData();
    formData.append("file", file)
    bus.$post("/material/addTemplateMaterial",formData).then(res =>{
      this.controlList[this.controlIndex].url = res.data.url
    }).catch(err =>{
      console.log(err);
    })
  }

}
