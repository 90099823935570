




















































































import {Component, Vue, Watch} from "vue-property-decorator";
import {State} from "vuex-class";
import pickerColor from "@/components/helang-pickerColor.vue"


@Component({
  components: {
    pickerColor
  }
})
export default class H5font extends Vue {
  public type = ""
  public alignmentActive = ""
  public defaultValue = 14
  private lineSpacing = 1 // 行间距
  private letterSpacing = 0 // 字体颜色
  public color = "#333333"
  public showPickerColor = false
  public bottomPickerColor = 0
  public activeNames: Array<string> = ['1']
  public taColor = "000000"

  @State("controlList") controlList
  @State("controlIndex") controlIndex

  get textarea() {
    return this.controlList[this.controlIndex] && this.controlList[this.controlIndex].text
  }

  set textarea(val: string) {
    this.controlList[this.controlIndex].text = val
  }

  // 字间距
  onfontChange(e) {
    this.controlList[this.controlIndex].letterSpacing = e
  }

  // 行间距
  onlineChange(e) {
    this.controlList[this.controlIndex].lineSpacing = e
  }

  // 字体大小
  onsizeChange(data) {
    this.controlList[this.controlIndex].fontSize = data
  }

  // 显示颜色
  showColor() {
    this.setType("")
    this.showPickerColor = true
  }


  // 字体颜色
  getPickerColor(color) {
    /* 隐藏弹窗 */
    this.showPickerColor = false
    this.activeNames = []
    /* 判断颜色值是否有效 */
    if (color) {
      this.controlList[this.controlIndex].fontColor = color
    }
  }

  // 对齐方式
  public setAlignment(data) {
    this.alignmentActive = data
    switch (data) {
      case "left":
        this.controlList[this.controlIndex].alignment = this.alignmentActive
        break
      case "center":
        this.controlList[this.controlIndex].alignment = this.alignmentActive
        break
      case "right":
        this.controlList[this.controlIndex].alignment = this.alignmentActive
        break
    }
  }

  public setType(type) {
    if (type === this.type) {
      this.type = ""
    } else {
      this.type = type
    }
  }

  getControlIndex(new_val) {
    this.alignmentActive = this.controlList[new_val].alignment
    this.defaultValue = this.controlList[new_val].fontSize
    this.lineSpacing = this.controlList[new_val].lineSpacing
    this.letterSpacing = this.controlList[new_val].letterSpacing
    this.color = this.controlList[new_val].fontColor
  }

  @Watch('controlIndex')
  getControlIndexs(index) {
    this.getControlIndex(index)
  }
}
